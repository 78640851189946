<template>
  <div
    :class="[
      'bg',
      {
        'bg-guest' : !userTokenAvailable,
        'logged-in': userTokenAvailable && !isMobile,
        'not-logged-in': !userTokenAvailable && isMobile,
        collapsed: this.$root.isCollapsed && !isMobile,
      },
    ]"
  >
    <div class="modal">
      <div
        class="frame-35654"
        :style="{ paddingTop: userTokenAvailable ? 0 : '2rem' }"
      >
        <div class="back-button" @click="goBack" v-if="userTokenAvailable">
          <i class="fa-solid fa-angle-left" style="margin-right: 10px"></i>
          Search
        </div>
        <div class="frame-35656">
          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">
                Company Name <span style="color: red">*</span>
              </div>
              <div class="frame-35739">
                <input
                  type="text"
                  :value="
                    selectedCompanyName !== '' ? selectedCompanyName : null
                  "
                  v-on:input="selectedCompanyName = $event.target.value.trim()"
                  placeholder="Enter Company Name*"
                  required
                  class="input-field"
                />
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">
                Country <span style="color: red">*</span>
              </div>
              <div class="frame-35740">
                <div
                  class="input-group"
                  style="
                    justify-content: center;
                    position: relative;
                    width: 100%;
                  "
                >
                  <input
                    type="text"
                    placeholder="Country/Region*"
                    :value="selectedCountry.name"
                    class="input-field"
                    ref="searchInput"
                    @input="updateSelectedCountry"
                    @click="showResults"
                  />
                  <div
                    class="country-results"
                    v-if="filteredCountries.length && showResultsFlag"
                    ref="countryResults"
                  >
                    <div
                      v-for="country in filteredCountries"
                      :key="country.id"
                      @click="selectCountry(country)"
                    >
                      <template v-if="isWindows()"
                        ><img
                          :src="country.flagImage"
                          alt="Flag"
                          class="flag-image"
                        />
                        {{ country.name }}</template
                      >
                      <template v-else
                        >{{ country.flagEmoji }} {{ country.name }}</template
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Upload Proforma Invoice</div>
              <div class="frame-357442">
                <input
                  style="padding: 10px 0"
                  id="file-upload"
                  type="file"
                  accept="image/*,.pdf,.doc,.docx,.rtf,.odt"
                  @change="handleFileUpload"
                />
              </div>
            </div>
          </div>

          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">
                Company Address <span style="color: red">*</span>
              </div>
              <textarea
                class="additional-address"
                placeholder="Enter Address (Minimum 4 words)"
                v-model.trim="additionalAddress"
                required
              ></textarea>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">More Information</div>
              <textarea
                class="additional-address"
                placeholder="Provide More Information About The Company (optional)"
                v-model.trim="additionalInformation"
              ></textarea>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Phone</div>
              <div
                class="input-group"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  background-color: white;
                  border: 1px solid rgba(87, 87, 87, 0.5);
                  border-radius: 14px;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <select
                  v-model="selectedCountryCode"
                  class="country-dropdown"
                  style="padding: 0 10px"
                >
                  <option value="" disabled>Select a country</option>
                  <option
                    v-for="country in countries"
                    :value="country"
                    :key="country.code"
                    class="country-option"
                  >
                    {{ country.name }} - (+{{
                      getFirstDialCode(country.dialCode)
                    }})
                  </option>
                </select>
                <input
                  type="tel"
                  v-model.trim="phone"
                  placeholder="Phone"
                  style="
                    width: 65%;
                    border: none;
                    outline: none;
                    padding: 10px 24px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  pattern="[0-9()+\()-]*"
                />
              </div>
            </div>
          </div>
          <div class="frame-35815" style="align-items: center">
            <div class="input-sections1">
              <div class="input-field-title">Landline</div>
              <div
                class="input-group"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  background-color: white;
                  border: 1px solid rgba(87, 87, 87, 0.5);
                  border-radius: 14px;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <input
                  type="tel"
                  v-model.trim="areaCode"
                  placeholder="Area code"
                  style="
                    width: 30%;
                    outline: none;
                    border-left: none;
                    border-top: none;
                    border-bottom: none;
                    border-right: 1px solid rgba(87, 87, 87, 0.5);
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    padding: 10px;
                    padding-left: 25px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  maxlength="8"
                  pattern="[0-9()+\()-]*"
                />
                <input
                  type="tel"
                  v-model.trim="landline"
                  placeholder="Enter Landline"
                  style="
                    width: 70%;
                    border: none;
                    outline: none;
                    padding: 10px 24px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  maxlength="25"
                  pattern="[0-9()+\()-]*"
                />
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Customer Name</div>
              <input
                id="customer-type"
                type="text"
                class="input-field"
                v-model.trim="name"
                :placeholder="
                  windowWidth <= 1156 ? 'Customer Name' : 'Your Customer Name'
                "
                required
              />
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Customer Type</div>
              <div
                style="
                  width: -webkit-fill-available;
                  border-radius: 13px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  background-color: white;
                "
              >
                <div
                  v-if="Object.keys(selectedCustomerType).length === 0"
                  @click="toggleCustomerTypeDropdown"
                  style="
                    position: absolute;
                    font-size: 80%;
                    color: #757575;
                    margin-left: 20px;
                    font-weight: 500;
                  "
                >
                  {{
                    windowWidth <= 1156
                      ? "Customer Type"
                      : "Select Your Customer Type"
                  }}
                </div>
                <select
                  v-model="selectedCustomerType"
                  ref="customerTypeDropdown"
                  title="Select Customer Type"
                  class="country-dropdown"
                  style="
                    width: 100% !important;
                    padding-bottom: 9px;
                    padding-top: 9px;
                    border: 1px solid rgba(17, 54, 90, 0.5) !important;
                    border-radius: 13px;
                    padding-left: 20px;
                  "
                >
                  <option value="" disabled selected>
                    Select Customer Type
                  </option>
                  <option
                    v-for="customerType in customertypes"
                    :value="customerType"
                    :key="customerType.name"
                    class="country-option"
                    style="padding-left: 20px"
                  >
                    {{ customerType.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">Reference Number</div>
              <input
                type="text"
                placeholder="Reference Number"
                class="input-field"
                v-model.trim="referenceNumber"
              />
            </div>
            <div class="input-sections1" v-if="userType == null">
              <div class="input-field-title">
                Deliver To <span style="color: red">*</span>
              </div>
              <input
                class="input-field"
                type="text"
                v-model="deliveryEmail"
                :placeholder="
                  email == null ? 'Enter Delivery Email' : `Deliver to ${email}`
                "
                required
              />
            </div>
            <div class="input-sections1" v-if="userType == null">
              <div class="input-field-title">
                Confirm Email <span style="color: red">*</span>
              </div>
              <input
                class="input-field"
                type="text"
                v-model="confirmDeliveryEmail"
                placeholder="Re-enter Delivery Email"
                required
              />
            </div>
            <div class="input-sections1" v-if="entityType === 'BANK'">
              <div class="input-field-title">
                Branch Name <span style="color: red">*</span>
              </div>
              <select
                v-model="BranchName"
                class="input-field dropdown-1"
                :style="{
                  color: BranchName === null ? '#757575' : '#000001',
                }"
              >
                <option value="null" disabled>
                  {{
                    branchList === null
                      ? "No Branches to show"
                      : "Select Branch"
                  }}
                </option>
                <option
                  v-for="(branchDetails, index) in branchList"
                  :value="branchDetails.branchName"
                  :key="index"
                  class="country-option"
                >
                  {{ branchDetails?.branchName }}
                </option>
                <option value="Other" class="country-option">Other</option>
              </select>
            </div>
            <div class="input-sections1" v-if="entityType === 'BANK'">
              <div
                class="input-field-title"
                v-if="entityType === 'BANK' && BranchName === 'Other'"
              >
                Other Branch Name <span style="color: red">*</span>
              </div>
              <input
                type="text"
                v-model.trim="otherBranchName"
                placeholder="Other Branch Name"
                class="input-field"
                required
                v-if="entityType === 'BANK' && BranchName === 'Other'"
              />
            </div>
          </div>
          <div class="frame-35814" style="width: 100%">
            <div class="frame-35806">
              <div class="select-your-report">Select Your Report</div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                "
                title="The report will be compiled using the most recent data that is available."
              >
                <div class="frame-35630">
                  <input
                    type="radio"
                    id="fresh"
                    class="rectangle-10"
                    :checked="selectedReportType === 'FRESH'"
                    @click="
                      selectReportType(
                        'FRESH',
                        selectedCountry?.productOptions?.REPORT_COMPREHENSIVE
                          ?.FRESH
                      )
                    "
                  />
                  <label for="remember-me" class="remember-me"
                    >Fresh Report</label
                  >
                </div>
                <div
                  class="in-8-hrs"
                  v-if="Object.keys(this.selectedCountry).length > 0"
                >
                  in
                  {{
                    selectedCountry !== null &&
                    selectedCountry?.productOptions?.REPORT_COMPREHENSIVE?.FRESH
                      ?.eta_hours
                  }}
                  hr{{
                    selectedCountry !== null &&
                    selectedCountry?.productOptions?.REPORT_COMPREHENSIVE?.FRESH
                      ?.eta_hours > 1
                      ? "s"
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
          <div
            style="width: 100%; display: flex; flex-direction: column"
            v-if="userType == null || !userDetails?.includes('POSTPAID_ORDERS')"
          >
            <div class="order-total">
              <span>Order Total</span>
              <div class="all-currency-wrapper">
                <span class="currency-title">Currency</span>
                <select v-model="selectedCurrency" class="currency-dropdown">
                  <option
                    v-for="currency in currencies"
                    :value="currency"
                    :key="currency.code"
                    class="country-option"
                  >
                    {{ currency.code.toUpperCase() }} - {{ currency.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="USD-wrapper">
              <div class="USD" style="font-weight: 600">
                <span
                  >Total {{ selectedCurrency.code.toUpperCase() }}
                  <span
                    style="font-size: 12px; font-weight: 500"
                    v-if="selectedCurrency.code !== 'usd'"
                    >( 1$ = {{ convertedRate?.toFixed(2) }}
                    {{ selectedCurrency.code.toUpperCase() }})</span
                  ></span
                ><span>
                  {{
                    Object.keys(this.selectedCountry).length > 0
                      ? convertedTotal?.toFixed(2)
                      : 0
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="notes">
            <div class="note-title">
              <span>
                <span style="font-weight: 600">Note: </span>
                <span class="note-text">
                  Please note that our reports do not cover Financial
                  Institutions or any firms involved in remittances, gambling,
                  foreign exchange, money transfer services, or any other
                  financial services or institutions. By placing an order, you
                  acknowledge and agree to these exclusions.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="frame-35647">
        <div
          class="order-btn"
          :style="[
            { backgroundColor: createButtonColor },
            { color: createButtonTextColor },
            { border: userType == null ? none : '2px solid #11365A' },
            {
              order: userType !== null ? 1 : 2,
            },
            {
              opacity: userType !== null ? cancelButtonColor : '1',
            },
          ]"
          :disabled="isOrderButtonDisabled"
          v-on:click.prevent="disabled === false ? handleButtonClick() : none"
        >
          {{ userType !== null ? "Checkout" : "Create" }}
        </div>
        <div
          class="cancel-btn"
          :style="[
            {
              opacity: userType !== null ? cancelButtonColor : '1',
            },
            {
              backgroundColor: userType !== null ? '#26BBC2' : 'white',
            },
            {
              order: userType !== null ? 2 : 1,
            },
            { border: userType !== null ? none : '2px solid #11365A' },
          ]"
          :disabled="userType !== null ? isOrderButtonDisabled : none"
          v-on:click.prevent="handleButtonClick1"
        >
          {{ userType !== null ? "Add To Cart" : "Cancel" }}
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
  <OrderConfirmationModal
    v-if="showOrderConfirmationModal"
    :selectedCompanyName="orderInfo?.companyName"
    :reportDetails="orderInfo?.reportDetails"
    :userEmail="orderInfo?.userEmail"
    @close="hideOrderConfirmation()"
  />
</template>
<script>
import {
  CreateOrder,
  AllCountries,
  AllCurrencies,
  FileUpload,
  AddToCart,
  GetBranchList,
} from "@/api/APIs";
import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
import OrderConfirmationModal from "@/views/Modals/OrderConfirmation.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ManualOrder",
  beforeRouteLeave(to, from, next) {
    sessionStorage.clear();
    next();
  },
  components: {
    Toastify,
    OrderConfirmationModal,
  },
  props: {},
  data() {
    return {
      uploadedFile: null,
      selectedReportDetails: {
        eta_hours: 5,
        purchase_credits: 75,
        purchase_usd: 75,
      },
      uploadedFileName: "",
      selectedCompanyName: "",
      selectedReportType: "FRESH",
      countryId: "",
      name: "",
      email: VueCookies.get("email"),
      phone: "",
      landline: "",
      areaCode: "",
      additionalAddress: "",
      additionalInformation: "",
      deliveryEmail: VueCookies.get("email"),
      confirmDeliveryEmail: "",
      referenceNumber: "",
      fileKey: "",
      includeReferenceNumber: false,
      selectedCountry: {},
      selectedCountryCode: {},
      currencies: [],
      selectedCurrency: {
        id: 1,
        name: "US Dollar",
        code: "usd",
      },
      customertypes: [
        { name: "BANK" },
        { name: "IMPORTER" },
        { name: "EXPORTER" },
      ],
      selectedCustomerType: {},
      countries: [],
      filteredCountries: [],
      showResultsFlag: false,
      currencyValue: null,
      windowWidth: window.innerWidth,
      userType: VueCookies.get("user-type")
        ? VueCookies.get("user-type")
        : null,
      showOrderConfirmationModal: false,
      orderInfo: null,
      disabled: false,
      isMobile: window.innerWidth < 1023,
      branchList: null,
      BranchName: null,
      otherBranchName: null,
      entityType: VueCookies.get("EntityType") || null,
    };
  },
  watch: {
    userType: {
      immediate: true,
      handler() {
        this.entityType = VueCookies.get("EntityType");
      },
    },
  },
  created() {
    this.selectedCompanyName = sessionStorage.getItem("selectedCompanyName");
    let selectedCountryData = JSON.parse(
      sessionStorage.getItem("selectedCountry")
    );
    this.selectedCountry =
      selectedCountryData && Object.keys(selectedCountryData).length > 0
        ? selectedCountryData
        : {};
    this.selectedCountryCode =
      selectedCountryData && Object.keys(selectedCountryData).length > 0
        ? selectedCountryData
        : {};
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    if (this.selectedReportType === "FRESH") {
      this.selectReportType(
        "FRESH",
        this.selectedCountry?.productOptions?.REPORT_COMPREHENSIVE?.FRESH
      );
    }
    this.fetchCountryList();
    this.fetchCurrenciesList();
    this.fetchAPIData();
    if (VueCookies.get("EntityType") === "BANK") {
      this.BranchesList();
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters(["userDetails"]),
    userTokenAvailable() {
      return this.$root.userToken !== null;
    },
    convertedTotal() {
      const orderTotalUSD = this.getOrderTotal();

      if (this.selectedCurrency.code === "usd") {
        return orderTotalUSD;
      } else {
        const conversionRate = this.currencyValue[this.selectedCurrency.code];
        return orderTotalUSD * conversionRate;
      }
    },
    convertedRate() {
      const orderTotalUSD = this.getOrderTotal();

      if (this.selectedCurrency.code === "usd") {
        return orderTotalUSD;
      } else {
        const conversionRate = this.currencyValue[this.selectedCurrency.code];
        return conversionRate;
      }
    },
    showToast() {
      return this.$refs.toastContainer.addToast(
        "Please fill all the mandatory fields to place an order.",
        { type: "error" }
      );
    },
    isOrderButtonDisabled() {
      if (this.userType !== null) {
        if (
          this.additionalAddress !== "" &&
          this.selectedCompanyName !== "" &&
          this.selectedCountry.name !== undefined
        ) {
          return true;
        }
      } else if (
        this.additionalAddress !== "" &&
        this.selectedCompanyName !== "" &&
        this.selectedCountry.name !== undefined &&
        this.deliveryEmail !== null &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.deliveryEmail) &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.confirmDeliveryEmail)
      ) {
        return true;
      }

      return false;
    },
    createButtonColor() {
      if (this.userType == null) {
        return this.isOrderButtonDisabled ? "#26BBC2" : "#a2dfe3";
      } else {
        return this.isOrderButtonDisabled ? "white" : "#f4f5f7";
      }
    },
    createButtonTextColor() {
      return this.isOrderButtonDisabled ? "#04162D" : "#969da7";
    },
    cancelButtonColor() {
      return this.isOrderButtonDisabled ? "1" : "0.5";
    },
    cancelButtonTextColor() {
      return this.isOrderButtonDisabled ? "#04162D" : "#11365a";
    },
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    async BranchesList() {
      try {
        const response = await GetBranchList(VueCookies.get("entityId"));
        if (response) {
          if (response.message !== "No branches found for given entityId !!!") {
            this.branchList = response?.allBranches;
            if (VueCookies.get("branch")) {
              const entityId = Number(VueCookies.get("branch"));
              const MatchingBranchName = response.allBranches?.find(
                (entry) => entry.id === entityId
              );
              this.BranchName = MatchingBranchName.branchName;
            }
          }
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    goBack() {
      const previousRoute = JSON.parse(sessionStorage.getItem("previousRoute"));
      if (previousRoute) {
        this.$router.push(previousRoute);
      } else {
        this.$router.go(-1);
      }
    },
    goBackToSearch() {
      this.$router.push('/search');
    },
    updateSelectedCountry(event) {
      this.selectedCountry = {
        ...this.selectedCountry,
        name: event.target.value,
      };
      this.filterCountries();
    },
    handleButtonClick() {
      if (this.userType !== null && this.isOrderButtonDisabled) {
        this.CartCheckout();
      } else if (!this.isOrderButtonDisabled) {
        this.showToast;
      } else {
        this.placeOrder();
      }
    },
    async CartCheckout() {
      await this.AddToCart(true);
    },
    handleButtonClick1() {
      if (this.userType !== null && this.isOrderButtonDisabled) {
        this.AddToCart(false);
      } else if (this.userType !== null && !this.isOrderButtonDisabled) {
        this.showToast;
      } else if (this.userType !== null) {
        return;
      } else if (this.userType == null) {
        this.goBack();
      }
    },
    toggleCustomerTypeDropdown() {
      this.$refs.customerTypeDropdown.focus();
    },
    selectReportType(type, selectedReport) {
      this.selectedReportType = type;
      this.selectedReportDetails = selectedReport;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    isWindows() {
      return /Windows/.test(window.navigator.userAgent);
    },
    async fetchAPIData() {
      try {
        const response = await fetch(
          "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json"
        );
        if (response.ok) {
          const data = await response.json();
          this.currencyValue = data.usd;
        } else {
          console.error("Failed to fetch API data:", response.statusText);
        }
      } catch (error) {
        console.error("Error occurred while fetching API data:", error);
      }
    },
    async fetchCurrenciesList() {
      try {
        const response = await AllCurrencies();
        this.currencies = await response.allCurrencies;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    getOrderTotal() {
      if (this.selectedReportType === "FRESH") {
        return this.selectedCountry?.productOptions?.REPORT_COMPREHENSIVE?.FRESH
          ?.purchase_usd;
      }
      return "";
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/rtf",
        "application/vnd.oasis.opendocument.text",
      ];

      if (
        !allowedTypes.includes(file.type) &&
        !file.type.startsWith("image/")
      ) {
        event.target.value = "";
        return this.$refs.toastContainer.addToast(
          "Invalid file type. Please upload a valid file.",
          { type: "error" }
        );
      }

      this.uploadedFile = file;
      this.uploadedFileName = file.name;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await FileUpload(formData);
        if (
          response &&
          response.message === "SUCCESS" &&
          response.fileResults.length > 0
        ) {
          const fileResult = response.fileResults[0];
          this.fileKey = fileResult.fileKey;
        }
      } catch (error) {
        this.loginError = "Error occurred during upload.";
        console.error(error);
      }
    },
    getFirstDialCode(dialCodeString) {
      return dialCodeString.split(",")[0];
    },
    formatPhoneNumber() {
      this.phone = this.phone.replace(/\D/g, "");
    },
    async fetchCountryList() {
      try {
        const response = await AllCountries(false, false);
        this.countries = await response.allCountries;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    filterCountries() {
      this.showResultsFlag = true;
      this.filteredCountries = this.countries.filter((country) =>
        country.name.toLowerCase().includes(this.selectedCountry?.name)
      );
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.filteredCountries = [];
    },
    showResults() {
      this.showResultsFlag = true;
      this.filteredCountries = this.countries;
      document.addEventListener("click", this.closeResultsOnClickOutside);
    },
    closeResultsOnClickOutside(event) {
      const searchInput = this.$refs.searchInput;
      const countryResults = this.$refs.countryResults;
      if (
        searchInput &&
        !searchInput.contains(event.target) &&
        countryResults &&
        !countryResults.contains(event.target)
      ) {
        this.showResultsFlag = false;
        document.removeEventListener("click", this.closeResultsOnClickOutside);
      }
    },
    isCountrySelected() {
      return this.countries.some(
        (country) =>
          JSON.stringify(country) === JSON.stringify(this.selectedCountry)
      );
    },
    async AddToCart(checkout) {
      if (!this.isCountrySelected()) {
        return this.$refs.toastContainer.addToast(
          "Please select a valid country from the dropdown.",
          { type: "error" }
        );
      }

      if (
        !this.selectedCompanyName ||
        this.selectedCompanyName.trim().length === 0
      ) {
        return this.$refs.toastContainer.addToast(
          "Company name cannot be empty or contain only blank spaces.",
          { type: "error" }
        );
      }

      const invalidAddressTexts = ["na", "n/a", "not applicable", "-"];
      const isInvalidAddress =
        this.additionalAddress &&
        invalidAddressTexts.includes(
          this.additionalAddress.trim().toLowerCase()
        );
      const addressWordCount =
        this.additionalAddress &&
        this.additionalAddress.trim().split(/\s+/).length;

      if (
        isInvalidAddress ||
        addressWordCount < 4 ||
        this.additionalAddress.trim().length === 0
      ) {
        return this.$refs.toastContainer.addToast(
          "Please enter a valid additional address with at least 4 words.",
          { type: "error" }
        );
      }

      const firstDialCode =
        this.phone !== "" &&
        this.getFirstDialCode(this.selectedCountryCode.dialCode);

      const phoneNumberLengths =
        this.selectedCountryCode && this.selectedCountryCode.phoneLength
          ? this.selectedCountryCode.phoneLength
              .split(",")
              .map((length) => parseInt(length.trim()))
          : [];
      if (
        this.phone !== "" &&
        this.phone.trim().length === 0 &&
        !phoneNumberLengths.includes(this.phone.length)
      ) {
        this.isLoading = false;
        return this.$refs.toastContainer.addToast(
          `Phone number length should be ${this.selectedCountryCode?.phoneLength} digits`,
          { type: "error" }
        );
      }

      if (this.landline !== "" && this.landline.trim().length === 0) {
        return this.$refs.toastContainer.addToast(
          `Landline number cannot contain only blank spaces.`,
          { type: "error" }
        );
      }

      if (this.landline !== "" && this.areaCode === "") {
        this.isLoading = false;
        return this.$refs.toastContainer.addToast(
          "Please enter the area code for the provided landline number.",
          { type: "error" }
        );
      }

      if (this.areaCode !== "" && this.areaCode.trim().length === 0) {
        return this.$refs.toastContainer.addToast(
          `Area code cannot contain only blank spaces.`,
          { type: "error" }
        );
      }

      if (
        VueCookies.get("EntityType") === "BANK" &&
        this.BranchName === "Other" &&
        !this.otherBranchName
      ) {
        this.isLoading = false;
        return this.$refs.toastContainer.addToast(
          "Please enter the other branch name.",
          { type: "error" }
        );
      }
      if (VueCookies.get("EntityType") === "BANK" && !this.BranchName) {
        this.isLoading = false;
        return this.$refs.toastContainer.addToast("Please select the branch.", {
          type: "error",
        });
      }

      if (this.referenceNumber && this.referenceNumber.trim().length > 0) {
        this.includeReferenceNumber = true;
      }

      const phoneNo =
        this.phone !== "" ? `+${firstDialCode}-${this.phone}` : "";
      const fullLandline =
        this.landline !== "" ? `${this.areaCode}-${this.landline}` : "";

      try {
        let address =
          this.clickedSearchResult?.additional_info?.business_address;
        if (!address) {
          address = "-";
        }
        const payload = {
          order: {
            subjectName: this.selectedCompanyName,
            subjectAddress: this.additionalAddress,
            subjectCountry: this.selectedCountry?.id,
            orderEmail: this.deliveryEmail,
            orderContactNumber: phoneNo,
            orderLandlineNumber: fullLandline,
            reportType: this.selectedReportType,
            reportDetails: this.selectedReportDetails,
            moreInformation: this.additionalInformation,
            customerName: this.name,
            referenceNumber: this.referenceNumber,
            includeReferenceNumber: this.includeReferenceNumber,
            customerType: this.selectedCustomerType?.name,
            currencyId: this.selectedCurrency?.id,
            reportManual: true,
            piFileKey: this.fileKey,
            ...(VueCookies.get("EntityType") === "BANK" && {
              misCommentsBranch:
                this.BranchName === "Other"
                  ? this.otherBranchName
                  : this.BranchName,
            }),
          },
        };
        const response = await AddToCart(payload);
        if (response && response.message == "Added to cart") {
          // this.goBack();
          this.isLoading = false;
          this.updateCartCount(response.cartSize);
          if (checkout) {
            this.$root.toggleCheckout();
          } else {
            this.goBackToSearch();
            this.$root.toggleCheckoutTooltip();
          }
        } else {
          this.isLoading = false;
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
      this.$root.hideLoader();
    },
    async placeOrder() {
      this.disabled = true;
      if (!this.isCountrySelected()) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please select a valid country from the dropdown.",
          { type: "error" }
        );
      }

      if (
        !this.selectedCompanyName ||
        this.selectedCompanyName.trim().length === 0
      ) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Company name cannot be empty or contain only blank spaces.",
          { type: "error" }
        );
      }

      const invalidAddressTexts = ["na", "n/a", "not applicable", "-"];
      const isInvalidAddress =
        this.additionalAddress &&
        invalidAddressTexts.includes(
          this.additionalAddress.trim().toLowerCase()
        );
      const addressWordCount =
        this.additionalAddress &&
        this.additionalAddress.trim().split(/\s+/).length;

      if (
        isInvalidAddress ||
        addressWordCount < 4 ||
        this.additionalAddress.trim().length === 0
      ) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please enter a valid additional address with at least 4 words.",
          { type: "error" }
        );
      }

      const firstDialCode =
        this.phone !== "" &&
        this.getFirstDialCode(this.selectedCountryCode.dialCode);

      const phoneNumberLengths =
        this.selectedCountryCode && this.selectedCountryCode.phoneLength
          ? this.selectedCountryCode.phoneLength
              .split(",")
              .map((length) => parseInt(length.trim()))
          : [];

      if (this.deliveryEmail !== this.confirmDeliveryEmail) {
        this.isLoading = false;
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Delivery email and confirm email do not match.",
          { type: "error" }
        );
      }
      if (
        this.phone !== "" &&
        this.phone.trim().length === 0 &&
        !phoneNumberLengths.includes(this.phone.length)
      ) {
        this.isLoading = false;
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Phone number length should be ${this.selectedCountryCode?.phoneLength} digits`,
          { type: "error" }
        );
      }
      if (this.landline !== "" && this.landline.trim().length === 0) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Landline number cannot contain only blank spaces.`,
          { type: "error" }
        );
      }
      if (this.landline !== "" && this.areaCode === "") {
        this.isLoading = false;
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please enter the area code for the provided landline number.",
          { type: "error" }
        );
      }

      if (this.areaCode !== "" && this.areaCode.trim().length === 0) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Area code cannot contain only blank spaces.`,
          { type: "error" }
        );
      }

      if (this.referenceNumber && this.referenceNumber.trim().length > 0) {
        this.includeReferenceNumber = true;
      }

      const phoneNo =
        this.phone !== "" ? `+${firstDialCode}-${this.phone}` : "";
      const fullLandline =
        this.landline !== "" ? `${this.areaCode}-${this.landline}` : "";

      try {
        let address =
          this.clickedSearchResult?.additional_info?.business_address;
        if (!address) {
          address = "-";
        }
        const payload = {
          subjectName: this.selectedCompanyName,
          subjectAddress: this.additionalAddress,
          subjectCountry: this.selectedCountry?.id,
          orderEmail: this.deliveryEmail,
          orderContactNumber: phoneNo,
          orderLandlineNumber: fullLandline,
          reportType: this.selectedReportType,
          reportDetails: this.selectedReportDetails,
          moreInformation: this.additionalInformation,
          customerName: this.name,
          referenceNumber: this.referenceNumber,
          includeReferenceNumber: this.includeReferenceNumber,
          customerType: this.selectedCustomerType?.name,
          currencyId: this.selectedCurrency?.id,
          reportManual: true,
          piFileKey: this.fileKey,
          ...(VueCookies.get("EntityType") === "BANK" && {
            misCommentsBranch:
              this.BranchName === "Other"
                ? this.otherBranchName
                : this.BranchName,
          }),
        };
        const response = await CreateOrder(payload);
        if (response && response.message == "SUCCESS") {
          this.disabled = false;
          if (response?.result?.paymentRequired === true) {
            window.location.href = response?.result?.paymentLink;
          } else {
            const userEmail = VueCookies.get("user");
            this.orderInfo = {
              companyName: this.selectedCompanyName,
              reportDetails: this.selectedReportDetails,
              userEmail,
            };
            this.isLoading = false;
            this.showOrderConfirmationModal = true;
            return;
          }
        } else {
          this.disabled = false;
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } catch (error) {
        this.disabled = false;
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
      this.$root.hideLoader();
    },
    hideOrderConfirmation() {
      this.showOrderConfirmationModal = false;
      this.goBackToSearch();
    },
  },
};
</script>
<style scoped>
.bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  box-shadow: none;
  height: calc(100vh - 65px);;
}
.bg-guest {
  height: calc(100vh - 93px);
}
.logged-in {
  margin-left: 18vw;
  padding-top: 65px;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.not-logged-in {
  width: 100%;
}
.disabled {
  background-color: gray;
  cursor: not-allowed;
}
.flag-image {
  width: 20px;
  height: 15px;
}
.modal {
  background: #f2f5f8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: scroll;
  width: 100%;
  height: 100%;
}
.frame-35805 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.input-sections1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: calc(33.33% - 10px);
}
.input-field-title {
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: left;
  width: 100%;
}
.frame-35654 {
  padding: 10px 0 10px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  height: -webkit-fill-available;
  padding: 0 40px;
  background: #f2f8ff;
}
.frame-35656 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.frame-35815 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35739 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
  width: 100%;
}
.frame-35559 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
}
.enter-company-name {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35740 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  width: 100%;
  position: relative;
}
.dropdown-1 {
  padding: 10px;
  height: 37.5px !important;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  height: auto !important;
  box-shadow: none;
  margin: 0;
  width: -webkit-fill-available;
  outline: none;
}
.frame-355592 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 40px;
  min-width: 150px;
  position: relative;
}
.frame-35818 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sri-lanka-lk {
  flex-shrink: 0;
  width: 14px;
  height: 11px;
  position: relative;
  overflow: hidden;
}
.vector {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
  overflow: visible;
}
.sri-lanka {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}
.vector-1 {
  flex-shrink: 0;
  width: 10px;
  height: 5px;
  position: relative;
  overflow: visible;
}
.frame-35816 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35741 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
}
.frame-355593 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 17px 24px 17px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 80px;
  min-width: 150px;
  position: relative;
}
.enter-address {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35745 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
}
.provide-more-information-about-the-company {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35744 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35819 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-355594 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 565px;
  height: 40px;
  min-width: 150px;
  position: relative;
}
.enter-customer-name {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35820 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-35560 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 565px;
  height: 40px;
  min-width: 150px;
  position: relative;
}
.select-customer-type {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35817 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35807 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.reference-number-optional {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
  position: relative;
}
.frame-35627 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.rectangle-10 {
  background: #ffffff;
  border-radius: 50%;
  border-style: solid;
  border-color: #18c3df !important;
  border-width: 2px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
  /* appearance: none;
  -webkit-appearance: none; */
}
/* .rectangle-10:checked {
  background-color: #18c3df !important;
  border-color: #18c3df;
}
.rectangle-10:checked::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
}

.rectangle-10:focus {
  outline: none;
} */
/* .rectangle-10:checked:after {
  content: "\2713";
  color: white !important;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */
.include-in-the-final-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-35743 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.upload-proforma-invoice-optional {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-357442 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-355595 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
}
.choose-file {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.no-file-chosen {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35814 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35806 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.select-your-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-35664 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35630 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  gap: 40px;
}
.fresh-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
}
.in-8-hrs {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Italic", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  font-style: true;
  position: relative;
}
.frame-35753 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 50%;
}
.frame-35631 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-356642 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.delivery-to-samplemail-gmail-com-span {
  color: #3e4855;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
.delivery-to-samplemail-gmail-com-span2 {
  color: #3e4855;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  text-decoration: underline;
}
.frame-35647 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 15px 40px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: sticky;
  background: white;
  bottom: 0;
  box-shadow: 0px -5px 36px 0px rgba(17, 54, 90, 0.08);
}
.order-btn {
  padding: 10px 24px 10px 24px !important;
  align-self: stretch !important;
  width: 100%;
  font-weight: 600;
  border-radius: 13px;
  font-family: "Montserrat-Regular", sans-serif;
  cursor: pointer;
  color: white;
}
.cancel-btn {
  padding: 10px 24px 10px 24px !important;
  align-self: stretch !important;
  background-color: white;
  width: 100%;
  font-weight: 600;
  border-radius: 13px;
  font-family: "Montserrat-Regular", sans-serif;
  cursor: pointer;
}
.input-field {
  border-radius: 13px;
  height: auto !important;
  box-shadow: none;
  width: -webkit-fill-available;
  outline: none;
  border: none;
  padding: 10px 25px;
  border: 1px solid rgba(17, 54, 90, 0.5);
  margin: 0;
}
.country-results {
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: white;
  border-radius: 15px;
  overflow-y: auto;
  top: 110%;
  z-index: 9999;
  box-shadow: 0px 16px 16px 0px rgba(20, 45, 77, 0.1);
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.country-results div {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 5px 0 5px 20px;
  text-align: left;
  font-size: 14px;
}
.country-results div:hover {
  background-color: #dee9f2;
}
.user-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.country-dropdown {
  width: 35% !important;
  outline: none;
  border: none !important;
}
.additional-address {
  width: -webkit-fill-available;
  font-family: "Montserrat-Regular", sans-serif;
  box-shadow: none;
  border-radius: 13px;
  outline: none;
  margin: 0;
}
textarea {
  height: 65px;
}
.order-total {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.USD-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}
.USD {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat-Regular", sans-serif;
}
.currency-title {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat-Regular", sans-serif;
}
.all-currency-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  gap: 15px;
}
.currency-dropdown {
  width: 22% !important;
  outline: none;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  padding: 10px;
}
.remember-me {
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
}
.notes {
  padding: 10px 20px;
  border-radius: 16px;
  background: white;
  box-shadow: -4px -4px 8px 0px #fff, 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  width: -webkit-fill-available;
}
.note-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Montserrat-Light", sans-serif;
  text-align: left;
  gap: 7px;
  font-weight: 500;
  font-size: small;
}
.note-text {
  text-align: left;
  padding-left: 5px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 13px;
  line-height: 150%;
}
.back-button {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #11365a;
  padding: 10px;
  /* border: 1px solid #11365a; */
  border-radius: 14px;
  opacity: 0.7;
}
.back-button:hover {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .bg {
    height: calc(100vh - 71.34px);
  }
  .bg-guest { 
    height: calc(100vh - 71.34px);
  }
  .back-button {
    font-size: 14px;
    margin-top: 10px;
  }
  .frame-35647 {
    padding: 15px;
    gap: 15px;
  }
  .notes {
    padding: 8px;
    border-radius: 8px;
  }
  .modal {
    width: 100%;
    /* height: auto; */
  }
  .note-title {
    font-size: 12px;
  }
  .input-sections1 {
    width: 100%;
  }
  .frame-35654 {
    max-height: calc(100vh - 71.34px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  svg {
    width: 25px;
    height: 25px;
  }
  .frame-35656 {
    gap: 10px;
  }
  .frame-35815,
  .frame-35816,
  .frame-35817,
  .frame-35814 {
    flex-direction: column;
    gap: 10px;
  }
  .frame-35744 {
    gap: 10px;
  }
  .input-field,
  .country-dropdown {
    border-radius: 8px !important;
  }
  .input-field,
  input[type="tel" i] {
    margin: 0;
    padding: 5px 20px !important;
    font-size: 16px;
  }
  .frame-35559 input {
    margin: 0;
    padding: 5px 20px !important;
    height: auto;
    font-size: 16px;
  }
  .frame-35739,
  .frame-35740,
  .additional-address,
  .frame-35807 {
    font-size: 16px;
  }
  textarea {
    height: 60px;
    margin: 0;
  }
  .frame-35744 div div {
    margin-left: 20px !important;
    text-align: left;
    width: fit-content !important;
  }
  .input-group {
    border-radius: 8px !important;
  }
  .input-group,
  .frame-35753 {
    width: 100% !important;
  }
  .frame-35744 div select {
    padding: 5px 20px !important;
  }
  .country-results div,
  .remember-me,
  .in-8-hrs,
  .all-currency-wrapper {
    font-size: 14px;
  }
  .upload-proforma-invoice-optional,
  .select-your-report {
    font-size: 14px;
  }
  .frame-35743 {
    padding: 5px 15px !important;
  }
  input[type="file" i] {
    padding: 8px 0 !important;
  }
  .order-total {
    margin-top: 20px;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .all-currency-wrapper {
    width: 100%;
    justify-content: space-between;
  }
  .frame-35630 {
    gap: 20px;
  }
  .currency-dropdown {
    width: 30% !important;
    height: 27.22px;
    padding: 0 10px;
  }
  .country-dropdown {
    height: 30.5px !important;
    padding: 0 10px;
    font-size: 16px;
  }
  .frame-35806 {
    width: 100%;
  }
}
@media only screen and (min-width: 1156px) and (max-width: 1220px) {
  .bg-guest { 
    height: calc(100vh - 73px);
  }
}
@media only screen and (min-width: 1022px) and (max-width: 1156px) {
  .bg-guest { 
    height: calc(100vh - 73px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1022px) {
  .bg { 
    height: calc(100vh - 79.61px);
  }
  .bg-guest { 
    height: calc(100vh - 79.61px);
  }
  .back-button {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .bg-guest { 
    height: calc(100vh - 79.61px);
  }
  .notes {
    padding: 12px;
  }
  .note-title {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1156px) and (max-height: 800px) {
  .frame-35654 {
    height: auto;
  }
}
</style>
