<template>
  <section class="who-we-serve-section">
    <h2 class="section-title2">Who We Serve</h2>
    <div
      class="service-grid"
      @mouseenter="pauseProgress"
      @mouseleave="resumeProgress"
    >
      <div class="institutions-container">
        <div
          v-for="(service, index) in services"
          :key="index"
          class="institution-item"
          :class="{ active: index === selectedIndex }"
          @click="selectService(index, service)"
          tabindex="0"
        >
          <div class="extra-wrapper-1">
            <div v-html="getServiceIcon(service.icon, index)"></div>
            <div class="institution-name">
              <strong>{{ service.title }}</strong>
              <p>{{ service.description }}</p>
            </div>
            <i
              v-if="this.$root.mobile"
              class="fa-solid"
              :class="index === selectedIndex ? 'fa-angle-up' : 'fa-angle-down'"
              :style="{
                color: index === selectedIndex ? 'white' : '#11365a',
                right: '15px',
                position: 'absolute',
              }"
            ></i>
            <div v-if="index === selectedIndex" class="main-progress-bar">
              <div
                class="main-progress"
                :style="{ width: `${mainProgress}%` }"
              ></div>
            </div>
          </div>
          <section
            class="onboarding-container"
            v-if="this.$root.mobile && index === selectedIndex"
          >
            <div class="onboarding-content">
              <div
                class="primary-section"
                v-for="(data, index) in selectedEntry"
                :key="index"
                :class="{ expanded: index === expandedIndex }"
              >
                <div class="section-header" @click="toggleSection(index)">
                  <h2 class="section-title">{{ data.title }}</h2>
                  <i
                    class="fa-solid"
                    :class="
                      index === expandedIndex ? 'fa-angle-up' : 'fa-angle-down'
                    "
                    style="color: #11365a"
                  ></i>
                </div>
                <template v-if="index === expandedIndex">
                  <template v-if="Array.isArray(data.description)">
                    <ul class="ul">
                      <li
                        class="sub-title"
                        v-for="(desc, i) in data.description"
                        :key="i"
                      >
                        <strong v-if="desc.includes(':')"
                          >{{ desc.split(":")[0] }}:</strong
                        >
                        <p>
                          {{
                            desc.includes(":")
                              ? desc.split(":")[1].trim()
                              : desc
                          }}
                        </p>
                      </li>
                    </ul>
                  </template>
                  <p v-else class="feature-description">
                    {{ data.description }}
                  </p>
                </template>
                <!-- <div v-if="index === expandedIndex" class="progress-bar">
                  <div
                    class="progress"
                    :style="{ width: `${featureProgress}%` }"
                  ></div>
                </div> -->
              </div>
            </div>
          </section>
        </div>
      </div>

      <section class="onboarding-container" v-if="!this.$root.mobile">
        <div class="onboarding-content">
          <div
            class="primary-section"
            v-for="(data, index) in selectedEntry"
            :key="index"
            :class="{ expanded: index === expandedIndex }"
          >
            <div class="section-header" @click="toggleSection(index)">
              <h2 class="section-title">{{ data.title }}</h2>
              <i
                class="fa-solid"
                :class="
                  index === expandedIndex ? 'fa-angle-up' : 'fa-angle-down'
                "
                style="color: #11365a"
              ></i>
            </div>
            <template v-if="index === expandedIndex">
              <template v-if="Array.isArray(data.description)">
                <ul class="ul">
                  <li
                    class="sub-title"
                    v-for="(desc, i) in data.description"
                    :key="i"
                  >
                    <strong v-if="desc.includes(':')"
                      >{{ desc.split(":")[0] }}:</strong
                    >
                    <p>
                      {{
                        desc.includes(":") ? desc.split(":")[1].trim() : desc
                      }}
                    </p>
                  </li>
                </ul>
              </template>
              <p v-else class="feature-description">
                {{ data.description }}
              </p>
            </template>
            <div v-if="index === expandedIndex" class="progress-bar">
              <div
                class="progress"
                :style="{ width: `${featureProgress}%` }"
              ></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhoWeServe",
  components: {},
  data() {
    return {
      selectedIndex: 0,
      selectedEntry: null,
      expandedIndex: 0,
      mainProgress: 0,
      featureProgress: 0,
      intervalId: null,
      isPaused: false,
      services: [
        {
          icon: `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M3.5 25.6667H24.5"
                stroke="#11365A"
                stroke-width="2.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 20.9999V12.8333"
                stroke="#11365A"
                stroke-width="2.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.668 20.9999V12.8333"
                stroke="#11365A"
                stroke-width="2.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.332 20.9999V12.8333"
                stroke="#11365A"
                stroke-width="2.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 20.9999V12.8333"
                stroke="#11365A"
                stroke-width="2.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.0013 2.33325L23.3346 8.16659H4.66797L14.0013 2.33325Z"
                stroke="#11365A"
                stroke-width="2.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>`,
          title: "Commercial Banks",
          imgSrc: "",
          description:
            "Comprehensive solutions for primary and secondary market",
          features: [
            {
              title: "Primary Side",
              description: [
                "Counterparty Due Diligence: Perform thorough pre-onboarding checks on potential counterparties, ensuring they meet compliance and risk standards.",
                "Supplier, Buyer, Subsidiary Onboarding: Seamless onboarding supported by verified, real-time data on global entities. Operational Counterparty Due Diligence for supporting a trade transaction",
              ],
            },
            {
              title: "Secondary Side",
              description: [
                "Ongoing Monitoring: Continually track the financial and operational health of counterparties and assets to stay ahead of potential risks.",
                "Asset Acquisition: Use real-time data to assess the credit profile of assets during acquisition, ensuring a full understanding of the risk landscape.",
              ],
            },
            {
              title: "Market Expansion",
              description:
                "Pinpoint mandates for expansion in cross-border trade and access pre-vetted counterparty reports, enabling your bank to strategically grow in new markets with confidence and with speed. We can provide geographical linkages /commodity based linkages to banks for prospecting",
            },
          ],
        },
        {
          icon: `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M23.3327 15.1666C23.3327 21 19.2493 23.9166 14.396 25.6083C14.1419 25.6944 13.8658 25.6903 13.6143 25.5966C8.74935 23.9166 4.66602 21 4.66602 15.1666V6.99995C4.66602 6.69054 4.78893 6.39379 5.00772 6.175C5.22652 5.9562 5.52326 5.83329 5.83268 5.83329C8.16602 5.83329 11.0827 4.43329 13.1127 2.65995C13.3598 2.44879 13.6743 2.33276 13.9993 2.33276C14.3244 2.33276 14.6389 2.44879 14.886 2.65995C16.9277 4.44495 19.8327 5.83329 22.166 5.83329C22.4754 5.83329 22.7722 5.9562 22.991 6.175C23.2098 6.39379 23.3327 6.69054 23.3327 6.99995V15.1666Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5 14.0001L12.8333 16.3334L17.5 11.6667"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          `,
          title: "Insurance Companies",
          description: "Risk assessment and claims management support",
          features: [
            {
              title: "Risk Assessment & Pre-Onboarding",
              description:
                "Evaluate cross-border clients and partners before committing to insuring assets, using comprehensive due diligence reports. The recency/real time data is cherry on the top for any analysis.",
            },
            {
              title: "Claims Management",
              description:
                "Utilize real-time intelligence to verify claims and assess the financial stability of insured entities in different geographies.",
            },
            {
              title: "Monitoring & Compliance",
              description:
                "Continuously monitor insured parties to ensure they remain compliant and reduce exposure to fraudulent claims through ongoing data updates.",
            },
          ],
        },
        {
          icon: `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M12.834 19.8334L15.1673 22.1667C15.3971 22.3966 15.67 22.5789 15.9702 22.7032C16.2705 22.8276 16.5923 22.8916 16.9173 22.8916C17.2423 22.8916 17.5641 22.8276 17.8644 22.7032C18.1647 22.5789 18.4375 22.3966 18.6673 22.1667C18.8971 21.9369 19.0794 21.6641 19.2038 21.3638C19.3282 21.0636 19.3922 20.7418 19.3922 20.4167C19.3922 20.0917 19.3282 19.7699 19.2038 19.4697C19.0794 19.1694 18.8971 18.8966 18.6673 18.6667"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.3336 16.3334L19.2503 19.2501C19.7144 19.7142 20.3439 19.975 21.0003 19.975C21.6566 19.975 22.2861 19.7142 22.7503 19.2501C23.2144 18.786 23.4751 18.1565 23.4751 17.5001C23.4751 16.8437 23.2144 16.2142 22.7503 15.7501L18.2236 11.2234C17.5673 10.568 16.6778 10.1998 15.7503 10.1998C14.8228 10.1998 13.9332 10.568 13.2769 11.2234L12.2503 12.2501C11.7861 12.7142 11.1566 12.975 10.5003 12.975C9.84389 12.975 9.21439 12.7142 8.75026 12.2501C8.28614 11.786 8.02539 11.1565 8.02539 10.5001C8.02539 9.84372 8.28614 9.21423 8.75026 8.7501L12.0286 5.47177C13.0929 4.41027 14.4808 3.73408 15.9727 3.55023C17.4646 3.36639 18.9751 3.6854 20.2653 4.45677L20.8136 4.78343C21.3104 5.08325 21.901 5.18724 22.4703 5.0751L24.5003 4.66677"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24.5007 3.5L25.6673 16.3333H23.334"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.50065 3.5L2.33398 16.3333L9.91732 23.9167C10.3814 24.3808 11.0109 24.6415 11.6673 24.6415C12.3237 24.6415 12.9532 24.3808 13.4173 23.9167C13.8814 23.4525 14.1422 22.823 14.1422 22.1667C14.1422 21.5103 13.8814 20.8808 13.4173 20.4167"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 4.66675H12.8333"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          `,
          title: "Trade Funds",
          description:
            "See transactional trade before underwriting counterparties",
          features: [
            {
              title: "Global Trade Insights & Pre-Onboarding",
              description:
                "Validate the financial and operational soundness of trade partners before initiating financing agreements.",
            },
            {
              title: "Ongoing Monitoring",
              description:
                "Track the performance and risk profile of counterparties in trade networks to adjust exposure to risks.",
            },
            {
              title: "Compliance & Risk Monitoring",
              description:
                "Stay compliant with global trade regulations while continuously monitoring your network for emerging risks.",
            },
          ],
        },
        {
          icon: `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M8.16602 23.3333H19.8327"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.666 23.3334C18.0827 20.4167 12.5993 15.8667 15.166 11.6667"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0827 10.9667C12.366 11.9 13.1827 13.5334 13.766 15.2834C11.4327 15.75 9.68268 15.75 8.16602 14.9334C6.76602 14.2334 5.48268 12.7167 4.66602 10.0334C7.93268 9.45003 9.79935 10.0334 11.0827 10.9667Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.4497 7.00008C15.5603 8.39023 15.1128 10.0173 15.1664 11.6667C17.3831 11.5501 19.0164 10.9667 20.1831 10.0334C21.3497 8.86675 22.0497 7.35008 22.1664 4.66675C19.0164 4.78341 17.4997 5.83341 16.4497 7.00008Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          `,
          title: "Int. Development Institutions",
          description: "Supporting global economic growth",
          features: [
            {
              title: "Pre-Onboarding & Project Due Diligence",
              description:
                "Perform in-depth checks on potential partners, suppliers, and recipients before launching cross-border projects.",
            },
            {
              title: "Ongoing Monitoring",
              description:
                "Track the progress and financial stability of project participants to mitigate risks during the project lifecycle.",
            },
            {
              title: "Risk Mitigation",
              description:
                "Leverage Credence Data to assess risks across regions and markets, ensuring better outcomes for projects.",
            },
          ],
        },
        {
          icon: `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M12.8333 17.4999H15.1667C15.7855 17.4999 16.379 17.2541 16.8166 16.8165C17.2542 16.3789 17.5 15.7854 17.5 15.1666C17.5 14.5477 17.2542 13.9543 16.8166 13.5167C16.379 13.0791 15.7855 12.8333 15.1667 12.8333H11.6667C10.9667 12.8333 10.3833 13.0666 10.0333 13.5333L3.5 19.8333"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.16602 24.5001L10.0327 22.8667C10.3827 22.4001 10.966 22.1667 11.666 22.1667H16.3327C17.616 22.1667 18.7827 21.7001 19.5993 20.7667L24.966 15.6334C25.4162 15.208 25.679 14.6211 25.6965 14.0019C25.714 13.3827 25.4848 12.7819 25.0593 12.3317C24.6339 11.8815 24.047 11.6188 23.4278 11.6013C22.8087 11.5838 22.2079 11.813 21.7577 12.2384L16.8577 16.7884"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.33398 18.6667L9.33398 25.6667"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.6665 13.8834C20.5351 13.8834 22.0499 12.3686 22.0499 10.5C22.0499 8.63147 20.5351 7.1167 18.6665 7.1167C16.798 7.1167 15.2832 8.63147 15.2832 10.5C15.2832 12.3686 16.798 13.8834 18.6665 13.8834Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 9.33325C8.933 9.33325 10.5 7.76625 10.5 5.83325C10.5 3.90026 8.933 2.33325 7 2.33325C5.067 2.33325 3.5 3.90026 3.5 5.83325C3.5 7.76625 5.067 9.33325 7 9.33325Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          `,
          title: "Non Bank Financial Institutions",
          description: "Enhancing credit risk analysis and monitoring",
          features: [
            {
              title: "Credit Risk Analysis & Pre-Onboarding",
              description:
                "Conduct pre-lending due diligence on cross-border borrowers and counterparties to determine creditworthiness.",
            },
            {
              title: "Portfolio Monitoring",
              description:
                "Continuously monitor the financial health of your borrowers and counterparties, receiving real-time updates on potential risks.",
            },
            {
              title: "Enhanced Decision Making",
              description:
                "Use real-time data to make risk-informed lending decisions and avoid potential defaults.",
            },
          ],
        },
        {
          icon: `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M9.33398 16.3333C13.2 16.3333 16.334 13.1992 16.334 9.33325C16.334 5.46726 13.2 2.33325 9.33398 2.33325C5.46799 2.33325 2.33398 5.46726 2.33398 9.33325C2.33398 13.1992 5.46799 16.3333 9.33398 16.3333Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.1042 12.0984C22.207 12.5096 23.1884 13.1922 23.9575 14.0831C24.7267 14.9741 25.2587 16.0446 25.5045 17.1956C25.7503 18.3467 25.7019 19.5412 25.3637 20.6685C25.0256 21.7959 24.4086 22.8198 23.5698 23.6456C22.7311 24.4714 21.6977 25.0723 20.5651 25.3928C19.4326 25.7134 18.2376 25.7431 17.0905 25.4794C15.9434 25.2157 14.8813 24.667 14.0025 23.8841C13.1236 23.1011 12.4564 22.1092 12.0625 21.0001"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.16602 7H9.33268V11.6667"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.4948 16.1934L20.3115 17.0217L17.0215 20.3117"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          `,
          title: "Export Credit Agencies",
          description: "Facilitating international trade with confidence",
          features: [
            {
              title: "Information on All Company Sizes",
              description:
                "Access critical financial and operational information on companies of all sizes, from SMEs to large corporates, supporting your diverse portfolio.",
            },
            {
              title: "SME Export Support",
              description:
                "Create targeted proposals for SMEs aiming to export, backed by accurate financial data to help mitigate risks.",
            },
            {
              title: "Credit Risk Mitigation",
              description:
                "Use our real-time intelligence to identify and manage credit risks in cross-border trade.",
            },
            {
              title: "Monitoring Portfolios",
              description:
                "Continuously monitor your cross-border portfolio, ensuring ongoing stability and reducing exposure to potential risks in international trade.",
            },
          ],
        },
        {
          icon: `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M18.666 11.6667H20.9993"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.666 16.3333H20.9993"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.19922 17.4999C7.43972 16.8163 7.8865 16.2241 8.47791 15.8052C9.06931 15.3863 9.77616 15.1614 10.5009 15.1614C11.2256 15.1614 11.9325 15.3863 12.5239 15.8052C13.1153 16.2241 13.5621 16.8163 13.8026 17.4999"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.4993 15.1667C11.788 15.1667 12.8327 14.122 12.8327 12.8333C12.8327 11.5447 11.788 10.5 10.4993 10.5C9.21068 10.5 8.16602 11.5447 8.16602 12.8333C8.16602 14.122 9.21068 15.1667 10.4993 15.1667Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23.334 5.83325H4.66732C3.37865 5.83325 2.33398 6.87792 2.33398 8.16659V19.8333C2.33398 21.1219 3.37865 22.1666 4.66732 22.1666H23.334C24.6227 22.1666 25.6673 21.1219 25.6673 19.8333V8.16659C25.6673 6.87792 24.6227 5.83325 23.334 5.83325Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          `,
          title: "Asset Managers",
          description: "Optimizing investment strategies with real-time data",
          features: [
            {
              title: "Pre-Onboarding & Risk-Adjusted Investment",
              description:
                "Conduct comprehensive due diligence on global assets before investing to ensure that opportunities align with risk profiles.",
            },
            {
              title: "Ongoing Monitoring",
              description:
                "Continuously monitor the health and performance of investments, with regular updates to mitigate risk exposure.",
            },
          ],
        },
        {
          icon: `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M22.1667 3.5H5.83333C4.54467 3.5 3.5 4.54467 3.5 5.83333V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V5.83333C24.5 4.54467 23.4553 3.5 22.1667 3.5Z"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 10.5001C3.5 9.88124 3.74583 9.28775 4.18342 8.85017C4.621 8.41258 5.21449 8.16675 5.83333 8.16675H22.1667C22.7855 8.16675 23.379 8.41258 23.8166 8.85017C24.2542 9.28775 24.5 9.88124 24.5 10.5001"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 12.8333H7C7.93333 12.8333 8.86667 13.1833 9.45 13.8833L10.7333 14.9333C12.6 16.7999 15.5167 16.7999 17.3833 14.9333L18.6667 13.8833C19.25 13.2999 20.1833 12.8333 21.1167 12.8333H24.5"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          `,
          title: "Alternative investors",
          description: "Do your sanity check before participating in assets",
          features: [
            {
              title: "Pre-Onboarding & Vetting Opportunities",
              description:
                "Conduct in-depth due diligence on non-traditional investment opportunities before entering into agreements, especially in emerging markets.",
            },
            {
              title: "Risk Management & Monitoring",
              description:
                "Ongoing monitoring of investment health ensures that alternative investments remain viable and low risk throughout their lifecycle.",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.selectedEntry = this.services?.[0]?.features;
    this.startProgress();
  },
  methods: {
    selectService(index, data) {
      this.selectedIndex = index;
      this.selectedEntry = data.features;
      this.expandedIndex = 0;
      this.resetProgress();
    },
    getServiceIcon(icon, index) {
      const strokeColor = index === this.selectedIndex ? "#FFFFFF" : "#11365A";
      return icon.replace(/stroke="#[A-Fa-f0-9]+"/g, `stroke="${strokeColor}"`);
    },
    toggleSection(index) {
      this.expandedIndex = index;
      this.resetProgress(true);
    },
    resetProgress(isFeatureOnly = false) {
      if (!isFeatureOnly) this.mainProgress = 0;
      this.featureProgress = 0;
      clearInterval(this.intervalId);
      this.startProgress();
    },
    startProgress() {
      const featureDuration = 10000;
      clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        if (this.isPaused) return;
        const mainDuration = this.selectedEntry
          ? this.selectedEntry.length * featureDuration
          : 0;
        if (mainDuration > 0) {
          this.mainProgress += (100 / mainDuration) * 50;
          if (this.mainProgress >= 100) {
            this.mainProgress = 0;

            this.selectedIndex =
              (this.selectedIndex + 1) % this.services.length;
            this.selectedEntry = this.services[this.selectedIndex].features;

            this.featureProgress = 0;
            this.expandedIndex = 0;
          }
        }
        this.featureProgress += (100 / featureDuration) * 50;
        if (this.featureProgress >= 100) {
          this.featureProgress = 0;
          if (this.selectedEntry) {
            this.expandedIndex =
              (this.expandedIndex + 1) % this.selectedEntry.length;
          }
        }
      }, 50);
    },
    pauseProgress() {
      this.isPaused = true;
    },
    resumeProgress() {
      this.isPaused = false;
    },
  },
};
</script>

<style scoped>
.main-progress-bar {
  background-color: #11365a;
  width: 100%;
  height: 8px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.progress-bar {
  height: 4px;
  background-color: #e0e0e0;
  width: 100%;
  position: relative;
  margin-top: 10px;
}
.progress {
  height: 100%;
  background-color: #11365a;
  width: 0%;
  transition: width 0.05s linear;
}
.main-progress {
  height: 100%;
  background-color: var(--Other-Yellow, #fcd600);
  width: 0%;
  transition: width 0.05s linear;
}
.extra-wrapper-1 {
  display: flex;
  gap: 20px;
  align-items: center;
  width: -webkit-fill-available;
}
.who-we-serve-section {
  background-color: #d0f3f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat, sans-serif;
  padding: 40px 0;
  width: 100%;
}

.section-title {
  font-size: 40px;
  color: #11365a;
  font-weight: 800;
  text-align: center;
  max-width: 806px;
  width: 100%;
  margin: 0;
}

.service-grid {
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1280px;
  width: 90%;
  margin-top: 60px;
  grid-template-columns: repeat(2, 1fr);
}

.institutions-container {
  display: flex;
  /* max-width: 628px; */
  flex-direction: column;
  color: rgba(17, 54, 90, 1);
  justify-content: center;
  justify-content: flex-start;
  gap: 15px;
}

.institution-name {
  font: 24px Montserrat, sans-serif;
  text-align: left;
}

.institution-name p {
  font-size: 16px;
  margin: 0;
  margin-top: 8px;
}

.institution-item {
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  gap: 24px;
  overflow: hidden;
  padding: 18px 24px;
  position: relative;
  cursor: pointer;
  outline: none !important;
}

.institution-item.active {
  background-color: rgba(17, 54, 90, 1);
  color: #fff;
}

.institution-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 28px;
}

.active-indicator {
  background: var(--Other-Yellow, #fcd600);
  width: -webkit-fill-available;
  height: 8px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.institution-item:first-child {
  margin-top: 0;
}

.institution-item:focus {
  outline: 2px solid #4a90e2;
  outline-offset: 2px;
}

.institution-item:hover {
  background-color: rgb(242, 242, 242);
}

.institution-item.active:hover {
  background-color: rgba(17, 54, 90, 0.9);
}

.onboarding-container {
  border-radius: 8px;
  background: #fff;
  display: flex;
  max-width: 628px;
  flex-direction: column;
  color: rgb(17, 54, 90);
  justify-content: center;
  padding: 24px;
  font: 700 24px/43px Montserrat, sans-serif;
  justify-content: flex-start;
}

.onboarding-content {
  display: flex;
  width: -webkit-fill-available;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.primary-section {
  border-radius: 8px;
  background-color: rgba(17, 54, 90, 0.1);
  display: flex;
  width: -webkit-fill-available;
  flex-direction: column;
  overflow: hidden;
  font-size: 16px;
  line-height: 29px;
  text-align: left;
}

.primary-section.expanded {
  background-color: rgba(17, 54, 90, 0.2);
}

.fa-angle-up,
.fa-angle-down {
  transition: transform 0.3s;
}

.section-header {
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  line-height: 43px;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 16px;
}

.section-title {
  flex: 1;
  margin: auto 0;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
}

.section-title2 {
  flex: 1;
  margin: auto 0;
  font-size: inherit;
  line-height: inherit;
  font-size: 40px;
}

.section-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}

.feature-title {
  margin-top: 8px;
  font-size: 16px;
  line-height: 29px;
}

.feature-description {
  font-weight: 400;
  margin-top: 0;
  font-size: 16px;
  line-height: 29px;
  padding: 0 16px;
}

.sub-title p {
  font-weight: 400;
}

.ul {
  padding: 0 16px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .service-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .institution-item {
    padding: 18px 12px;
  }
  .institution-item.active {
    flex-direction: column;
  }
  .ul {
    background: transparent;
  }
  .section-title2 {
    font-size: 24px;
  }
  .institution-name {
    font-size: 18px;
    margin-right: 18px;
  }
  .institution-name p {
    font-size: 14px;
  }
  .onboarding-container {
    padding: 8px;
  }
  .section-title {
    font-size: 16px;
    line-height: 20px;
  }
  .fa-angle-up,
  .fa-angle-down {
    font-size: 16px;
  }
  .feature-description,
  .sub-title p {
    line-height: 20px;
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
